@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  height: 100%;
}

.app-container {
  height: calc(100% - 145.98px);
  overflow-y: scroll;
}

.container {
  height: calc(100% - 4rem - 81.15px)
}

.navbar {
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

.linkNoStyle {
  text-decoration: none;
  color: inherit;
}


/* CSS for when the viewport width is less than 500px */
@media (max-width: 990px) {
  /* Styles for elements when viewport width is less than 500px */
  .hideWhenCollapsed {
    display: none !important; /* This will hide the element */
  }
}

/* CSS for when the viewport width is less than 500px */
@media (max-width: 500px) {
  /* Styles for elements when viewport width is less than 500px */
  .hideWhenCollapsedPhone {
    display: none !important;
  }
}

@media (min-width: 991px) {
  .showWhenCollapsed {
    display: none;
  }
}

/* edit profile */
.cropper {
  position: relative;
  height: 50vh;
}

.viewer {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.output {
  position: relative;
  width: 300px;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.output img {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}


/* /profile */
#profileHeart {
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
}


.buttonWithOutline {
  border: 1px solid gray;
  border-radius: 2%;
  margin-left: 5px;
  margin-right: 5px;
}

.bioText {
  text-align: left;
}


.profileCard {
  height: 95%
}

.profileCard:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.069);
}

.imageContainer {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  margin-top: auto;
  margin-bottom: auto;
}

.nolink {
  color: inherit;
  text-decoration: none;
}

.nolink:hover,
.nolink:active,
.nolink:focus {
  color: inherit;
  text-decoration: none;
}

.form-control-bootstrap-default {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #f54b81;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(183, 10, 65, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

 .m_8fb7ebe7:focus {
  border: 10px solid red !important; 
  border-radius: 0.25rem !important;
  border-color: #f54b81 !important;
  box-shadow: 0 0 0 0.2rem rgba(183, 10, 65, 0.25) !important;
 }  

.mantine-TagsInput-pillsList:focus {
  border-radius: 0.25rem !important;
  border-color: #f54b81 !important;
  box-shadow: 0 0 0 0.2rem rgba(183, 10, 65, 0.25) !important;
}

.m_8fb7ebe7:focus, .m_8fb7ebe7:focus-within {
  border-radius: 0.25rem !important;
  border-color: #f54b81 !important;
  box-shadow: 0 0 0 0.2rem rgba(183, 10, 65, 0.25) !important; 
}

.searchListItem {
  border: 1px solid rgba(128, 128, 128, 0.069);;
}
.searchListItem:hover {
  background-color: rgba(128, 128, 128, 0.069);
  cursor: pointer; 
}

.post-list-item:hover {
  background-color: rgba(128, 128, 128, 0.069);
  cursor: pointer;
}
