/*body {
  min-height: 100vh;
}*/

.container{
/*  color: red;*/
  height: 100%;
}

.text-small {
  font-size: 0.9rem;
}

/*.messagesBox,*/

.chat-box {
  overflow-x: scroll;
}


.rounded-lg {
  border-radius: 0.5rem;
}

.chatMessagesContainerAboveTextInput {
  height: calc(100% - 100px);
}

.chatMessagesContainer {
  height: 100%
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}

/*.messagesContainer {
  height: 100vh;
  background-color: red;
}*/